export enum QueryKey {
  REPORTS = 'reports',
  REPORT = 'report',
}

export enum RouteName {
  HOME = 'reports-home',
  NEW = 'reports-new',
  EDIT = 'reports-edit',
}

export enum BaseAssetField {
  ASSET_TYPE = 'Asset Type',
  LOCATION = 'Location',
  LOANEE = 'Loanee',
}

export const EMPTY_FILTER_VALUE = '__empty__';
